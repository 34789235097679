export default {  
    // 开发环境的 API 地址  
    dev: {  
      apiUrl: 'https://qipengapi.snlvy.com/'//  'http://localhost:8085/' // 'https://aiapi.qipengai.com/'//   'http://47.113.121.221:8085/'// 
    },  
    // 生产环境的 API 地址  
    prod: {  
      apiUrl: 'http://47.113.121.221:8085/'  
    },  
    // 其他配置...  
    common:{
      formattedDate(times) {  
          if(times<=0){
              return "";
          }
          const date = new Date(times * 1000); // 乘以 1000 转换为毫秒  
          // 获取年份、月份（注意月份是从0开始的，所以需要+1）、日期、小时、分钟和秒  
          const year = date.getFullYear();  
          const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份补零  
          const day = String(date.getDate()).padStart(2, '0'); // 日期补零  
          const hours = String(date.getHours()).padStart(2, '0'); // 小时补零  
          const minutes = String(date.getMinutes()).padStart(2, '0'); // 分钟补零  
          const seconds = String(date.getSeconds()).padStart(2, '0'); // 秒补零  
      
          // 拼接成 yyyy-MM-dd HH:mm:ss 格式  
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; 
      },
      formatTimeStamp:function(dateTimeString){
          if(dateTimeString==''){
              return 0;
          }
          const dateTime = new Date(dateTimeString);  

          // 检查日期是否有效  
          if (isNaN(dateTime.getTime())) {  
              return 0;
          } else {  
              const timestamp = dateTime.getTime();  
              return timestamp
          }
      },
      formatAmount(amount,divisor,fixed){
          try{
              if(amount == undefined || amount == ''){
                  return '0'
              }
              let amo=Number(amount);
              let div=Number(divisor);
              let fix=Number(fixed);

              if(div==undefined || div=='' || div==0){
                  div = 100;
              }
              if(fix==undefined || fix=='' || fix==0){
                  fix = 2;
              }
              const rst = (amo/div).toFixed(fix)
              if(rst ==0 ){
                  return 0;
              }else{
                  return rst;
              }
          }catch(err){
              return amount
          }
      },
    }
  };