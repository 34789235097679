<template>
    <div>
        <el-row>
            <el-col :span="24">
               <div class="title">套餐管理</div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="20">
                        <div class="query">
                            <div class="content">
                                套餐名称：
                                <el-input
                                    type="text"
                                    placeholder="输入套餐名称"
                                    v-model="query.tcName"
                                    maxlength="11"
                                    style="width: 150px !important;">
                                </el-input>
                                <el-button type="success" @click="initpackList(1)">查询</el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div style="margin-bottom: 20px; text-align: right;">
                            <el-button type="primary" @click="toAdd">添加套餐</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-table
                    ref="table"
                    :data="packList.data"
                    tooltip-effect="dark"
                    border
                    stripe
                    header-row-class-name="tbHeader"
                    @selection-change="handleSelectionChange">
                    <el-table-column
                        v-if="isShowIdColumn"
                        prop="id"
                        label="用户ID"
                        >
                    </el-table-column>
                    <el-table-column
                        type="index"
                        label="序号"
                        width="50"
                        >
                    </el-table-column>
                    <el-table-column
                        label="套餐名称"
                        prop="tcName">
                    </el-table-column>
                    <el-table-column
                        label="原价(元)"
                        prop="amount">
                        <template slot-scope="scope">
                            <el-tag
                            type="info"
                            style="text-decoration: line-through; font-weight: 600; font-size:18px;"
                            disable-transitions>￥{{scope.row.amount}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="优惠价(元)"
                        prop="amountYH">
                        <template slot-scope="scope">
                            <el-tag
                            style="font-weight: 600; font-size:18px;"
                            disable-transitions>￥{{scope.row.amountYH}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="有效期(天)"
                        prop="endDays">
                        <template slot-scope="scope">
                            {{scope.row.endDays}} 天
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="状态">
                        <template slot-scope="scope">
                            <el-tag
                            :type="scope.row.status === 1 ? 'success' : 'danger'"
                            disable-transitions>{{scope.row.status==1?"有效":"无效"}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="100">
                        <template slot-scope="scope">
                            <el-button type="success"  @click="handleClick_Edit(scope.row)">编辑</el-button>
                            <el-button type="danger"  @click="handleClick_Delete(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="packList.count"
                    :page-size="pageSize"
                    @current-change="initpackList">
                </el-pagination>
            </el-col>
        </el-row>
        
        <!-- Dialog组件 -->
        <el-drawer class="my-custom-dialog" :visible.sync="dialogpackAdd" :title="form.title" size="50%" direction="rtl" @close="closeDialog">
            <!-- 引入Vue文件 -->  
            <el-row>
                <el-col :span="5">&nbsp;</el-col>
                <el-col :span="14">
                    <el-card>
                        <el-form ref="packForm" :model="form" label-width="80px">
                            <el-form-item label="套餐名称">
                                <el-input v-model="form.tcName"></el-input>
                            </el-form-item>
                            <el-form-item label="原价">
                                <el-input type="number" v-model="form.amount"></el-input>
                            </el-form-item>
                            <el-form-item label="优惠价">
                                <el-input type="number" v-model="form.amountYH"></el-input>
                            </el-form-item>
                            <el-form-item label="有效期">
                                <el-input type="number" v-model="form.endDays"></el-input>
                            </el-form-item>
                            <el-form-item label="状态" style="text-align:left;">
                                <el-radio-group v-model="form.status">
                                    <el-radio :label="1" border>有效</el-radio>
                                    <el-radio :label="0" border>无效</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item style="text-align: center;">
                                <el-button type="primary" @click="onSubmit">保存</el-button>
                                <el-button @click="closeDialog">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="5"></el-col>
            </el-row>
        </el-drawer>
    </div>
</template>

<style>
.tbHeader,.tbHeader th{
    background-color: #fafafa !important;
    color:#333;
    font-weight: 500;
}
.title{
    font-size: 20px;
    text-align: left; 
    /* text-indent: 2em; 
    background: #f5f7fa75;  */
    height: 30px; 
    line-height: 30px;
    margin-bottom: 20px;
}
.query{
    margin-bottom: 20px; 
    text-align: left;
}
/* .query .el-input{
    width:150px ;
} */
.query .content .el-button{
    margin-left:10px;
}
</style>

<script>
// import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'

    export default {
        name: 'taocanManage',
        data() {
            return {
                dialogpackAdd:false,
                isShowIdColumn:false,
                query:{
                    tcName:'',
                },
                pageSize:10,
                packList:[],

                form:{
                    id:0,
                    tcName:'',
                    amount:0,
                    amountYH:0,
                    status:1,
                    endDays:0,
                    title:''
                },
            };
        },
        mounted(){
            this.init();
        },
        beforeDestroy(){
        },
        methods:{
            init:function(){
                this.initpackList(1)
            },
            closeDialog(){
                this.form.id=0;
                this.form.tcName=''
                this.form.status=1
                this.form.amount=0
                this.form.amountYH=0
                this.form.endDays=0
                this.form.title=''
                this.dialogpackAdd=false
            },
            greCurrentStamp(){
                let timestamp = Date.now();
                return timestamp;
            },
            async initpackList(pageIndex){
                try {
                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'admin/pack/list';
                    const response = await axios.post(loginUrl, {
                        tcName:this.query.tcName,
                        pageSize:this.pageSize,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        resD.data.forEach(u => {
                            let timestamp = this.greCurrentStamp()
                            let timestamp1 = timestamp+7*24*60*60*100;
                            if(u.expressTime*1000<timestamp){
                                u.isExpress = 2
                            }else if(u.expressTime*1000>=timestamp && u.expressTime*1000<timestamp1){
                                u.isExpress = 1
                            }else {
                                u.isExpress = 0
                            }
                            u.expressTimeS = $conf.common.formattedDate(u.expressTime);
                        });
                        this.packList=resD;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            handleSelectionChange(){

                
            },
            handleDateChange(value){
                this.form.expressTime = $conf.common.formatTimeStamp(value)/1000
            },
            toAdd(){
                this.dialogpackAdd=true
                this.form.id=0;
                this.form.tcName=''
                this.form.status=1
                this.form.amount=0
                this.form.amountYH=0
                this.form.endDays=0
                this.form.title="添加套餐"
            },
            handleClick_Edit(row){
                this.dialogpackAdd=true
                this.form.title="编辑套餐"
                this.form.id=row.id;
                this.form.tcName=row.tcName
                this.form.status=row.status
                this.form.amount=row.amount
                this.form.amountYH=row.amountYH
                this.form.endDays=row.endDays
                console.log(row);

            },
            async handleClick_Delete(row){
                let vm = this
                const h = this.$createElement;
                this.$msgbox({
                    title:'确认信息',
                    message:h('p', null, [
                        h('span', null, '注意：系统'),
                        h('b', { style: 'color: teal' }, '不建议'),
                        h('span', null, '删除套餐！可以选择关闭此套餐；如果确定执意要删除，请选择[执意删除]，删除后不可恢复！ ')
                    ]),
                    distinguishCancelAndClose: true,
                    showCancelButton:true,
                    confirmButtonText: '设置关闭',
                    cancelButtonText: '执意删除'
                })
                .then(() => {
                    vm.form.id=row.id;
                    vm.form.tcName=row.tcName
                    vm.form.status=0
                    vm.form.amount=row.amount
                    vm.form.amountYH=row.amountYH
                    vm.form.endDays=row.endDays
                    vm.onSubmit();
                })
                .catch(async action => {
                    if(action === 'cancel'){
                        try {
                            const loginUrl=$conf.dev.apiUrl+'admin/pack/delete';
                            const response = await axios.post(loginUrl, {
                                id:row.id,
                            },{
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            });  
                            const resD = response.data;
                            if(resD.code==0){
                                this.$message.success({
                                    message:resD.data,
                                    duration:1000,
                                    onClose:function(){
                                        vm.initpackList(1)
                                        vm.closeDialog()
                                    }
                                })
                            }else{
                                this.$message.error(resD.msg);  
                            }
                        } catch (error) {  
                            this.$message.error(error);  
                        } 
                    }
                });
            },
            async onSubmit(){
                console.log(this.form)
                let vm = this
                try {
                    const loginUrl=$conf.dev.apiUrl+'admin/pack/save';
                    const response = await axios.post(loginUrl, {
                        id:this.form.id,
                        tcName:this.form.tcName,
                        status:this.form.status,
                        amount:this.form.amount,
                        amountYH:this.form.amountYH,
                        endDays:this.form.endDays,
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.$message.success({
                            message:resD.data,
                            duration:1000,
                            onClose:function(){
                                vm.initpackList(1)
                                vm.closeDialog()
                            }
                        })
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            }
        }
    }
</script>